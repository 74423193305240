$(document).ready(function () {
  let paymentBtn = document.getElementsByClassName('payment_btn')[0]
  let paymentNote = document.getElementsByClassName('payment_note')[0]
  $('#paymentModal').on('show.bs.modal', function (e) {
    paymentBtn.href = e.relatedTarget.dataset.paymentLink
    paymentNote.innerText = e.relatedTarget.dataset.paymentNote
  })
  if (paymentBtn != null) {
    paymentBtn.addEventListener('click', function (e) {
      $('#paymentModal').modal('hide')
    })
  }
})
