import Plyr from "plyr";
$(document).ready(function () {
  var audioWrapper = document.querySelector("#audio-wrapper");
  if (audioWrapper != null) {
    var questionId = audioWrapper.dataset.questionId;
    var answerField = document.getElementById(
      `attempt_responses_attributes_${questionId}_answer`
    );
  }
  const player = new Plyr("#player", {
    // For now don't disable keyboard shortcurs and seek
    keyboard: {
      focused: false,
    },
    listeners: {
      seek: function (e) {
        e.preventDefault();
        return false;
      },
    },
  });

  player.on("ended", function (event) {
    const submit_button = $(".video_button");
    submit_button.prop("disabled", false);
    answerField.value = "ended";
  });
  /*
    player.duration gives total duration of media
    player.on('playing', function(event){
      event.detail.plyr returns player instance
    })
  */
  const submit_button = $(".video_button");
  const status = submit_button.data("status");
  const page_type = submit_button.data("page-type");
  console.log(status);
  if (page_type === "video" && status == false) {
    submit_button.prop("disabled", true);
  }
});
