;(function ($) {
  $(document).ready(function () {
    var searchParams = new URLSearchParams(location.search)
    currentLang = searchParams.has("locale") ? searchParams.get("locale") : 'es'
    const translations = {
      en: {
        please_select: 'Please select',
      },
      es: {
        please_select: 'Seleccione alguna opción',
      },
    }
    var optionTranslation = translations[currentLang]['please_select']
    var current_user_id = $('#current_user_id').val()
    
    $(document).on('change', '.user_roles_territory_select', function () {
      territory_tree(this)
    })

    // var locality_id = $('#user_locality option:selected').val();
    $(document).on('change', '.user_roles_locality_select', function () {
      locality_tree(this)
    })

    $(document).on('change', '.user_roles_section_select', function () {
      $('.user-roles-team-group-info ').show()
      var section_id = this.value
      clear_team_group_fields(this)
      console.log(
        'User Roles secion select changed with section ID',
        section_id,
      )
      if (section_id) {
        if (section_id > 0) {
          groups_by_section(this)
        }
      }
    })
    $(document).on('change', '.user_roles_group_select', function () {
      var group_id = this.value
      console.log('User Roles team select changed with team ID', group_id)
      if (group_id) {
        console.log('TD')
        teams_by_group(this)
      }
    })

    function territory_tree(obj) {
      var territory_id = obj.value
      var territorySelect = $(document.getElementById(obj.id))
      var localitySelect = $(
        document.getElementById(
          obj.id.split('_at_territory')[0].concat('_at_locality'),
        ),
      )
      $.ajax({
        type: 'GET',
        url: '/crm/users/territory_tree_for_roles_and_permissions',
        data: { id: territory_id, current_user_id: current_user_id },
      }).then(function (data) {
        console.log('Territories are:', data.territories)
        //$(document.getElementById(obj.id)).empty();
        territorySelect.empty()
        var newOption1 = new Option(optionTranslation, '', false, false)
        territorySelect.append(newOption1).trigger('change.select2')
        // territorySelect.select2({data: data.territories}).trigger('change.select2')
        $.each(data.territories, function (key, value) {
          territorySelect.append(
            '<option value=' + value.id + '>' + value.text + '</option>',
          )
        })
        territorySelect
          .find('option[value=' + territory_id + ']')
          .attr('selected', 'selected')
        if (data.localities.length > 0) {
          localitySelect.empty()
          var newOption2 = new Option(optionTranslation, '', false, false)
          localitySelect.append(newOption2).trigger('change.select2')
          //localitySelect.select2({data: data.localities}).trigger('change.select2')
          $.each(data.localities, function (key, value) {
            localitySelect.append(
              '<option value=' + value.id + '>' + value.text + '</option>',
            )
          })
          //localitySelect.find('option[value=' + selected_locality + ']').attr('selected', 'selected')
        }
      })
    }
    function locality_tree(obj) {
      var locality_id = obj.value
      var localitySelect = $(document.getElementById(obj.id))
      var sectionSelect = $(
        document.getElementById(
          obj.id.split('_at_locality')[0].concat('_at_section'),
        ),
      )
      $.ajax({
        type: 'GET',
        url: '/crm/users/locality_tree_for_roles_and_permissions',
        data: { id: locality_id, current_user_id: current_user_id },
      }).done(function (data) {
        // manually trigger the `select2:select` event
        var newOption1 = new Option(optionTranslation, '', false, false)
        var newOption2 = new Option(optionTranslation, '', false, false)
        localitySelect.empty().append(newOption1)
        sectionSelect.empty().append(newOption2)
        $.each(data.localities, function (key, value) {
          localitySelect.append(
            '<option value=' + value.id + '>' + value.text + '</option>',
          )
        })
        localitySelect.val(locality_id).attr('selected', 'selected')
        if (data.sections.length > 0) {
          $.each(data.sections, function (key, value) {
            sectionSelect.append(
              '<option value=' + value.id + '>' + value.text + '</option>',
            )
          })
        }
      })
    }
    $('.user_roles_territory_select').each(function () {
      console.log('The Appended Terrirtories are:', this.value)
      var territorySelect = $(document.getElementById(this.id))
      var localitySelect = $(
        document.getElementById(
          this.id.split('_at_territory')[0].concat('_at_locality'),
        ),
      )
      var sectionSelect = $(
        document.getElementById(
          this.id.split('_at_territory')[0].concat('_at_section'),
        ),
      )
      var groupSelect = $(
        document.getElementById(
          this.id.split('_at_territory')[0].concat('_at_group'),
        ),
      )
      var teamSelect = $(
        document.getElementById(
          this.id.split('_at_territory')[0].concat('_at_team'),
        ),
      )
      var selectedTerritoryId = this.value
      var selectedLocalityId = localitySelect.val()
      var selectedSectionId = sectionSelect.val()
      var selectedGroupId = groupSelect.val()
      var selectedTeamId = teamSelect.val()
      console.log(
        'The selected locality for territory %s is %s and selected section is %s',
        territorySelect.val(),
        localitySelect.val(),
        sectionSelect.val(),
      )
      $.ajax({
        type: 'GET',
        url: '/crm/users/territory_tree_for_roles_and_permissions',
        data: { id: selectedTerritoryId, current_user_id: current_user_id },
      }).done(function (data) {
        console.log('Territories are:', data.territories)
        territorySelect.empty()
        var newOption1 = new Option(optionTranslation, '', false, false)
        territorySelect.append(newOption1).trigger('change.select2')
        $.each(data.territories, function (key, value) {
          territorySelect.append(
            '<option value=' + value.id + '>' + value.text + '</option>',
          )
        })
        if (selectedTerritoryId.length > 0) {
          territorySelect
            .find('option[value=' + selectedTerritoryId + ']')
            .attr('selected', 'selected')
        }
        if (data.localities.length > 0) {
          localitySelect.empty()
          var newOption2 = new Option('Select', '', false, false)
          localitySelect.append(newOption2).trigger('change.select2')
          $.each(data.localities, function (key, value) {
            localitySelect.append(
              '<option value=' + value.id + '>' + value.text + '</option>',
            )
          })
          if (selectedLocalityId.length > 0) {
            localitySelect
              .find('option[value=' + selectedLocalityId + ']')
              .attr('selected', 'selected')
          }
        }
        if (selectedLocalityId != null){
          if (selectedLocalityId.length > 0) {
            $.ajax({
              type: 'GET',
              url: '/crm/users/locality_tree_for_roles_and_permissions',
              data: { id: selectedLocalityId, current_user_id: current_user_id },
            }).done(function (data) {
              // manually trigger the `select2:select` event
              var newOption1 = new Option(optionTranslation, '', false, false)
              sectionSelect.empty().append(newOption1)
              if (data.sections.length > 0) {
                $.each(data.sections, function (key, value) {
                  sectionSelect.append(
                    '<option value=' + value.id + '>' + value.text + '</option>',
                  )
                })
                if (selectedSectionId.length > 0) {
                  sectionSelect
                    .find('option[value=' + selectedSectionId + ']')
                    .attr('selected', 'selected')
                }
              }
            })
          }
        }
        if (selectedSectionId != null){
          if (selectedSectionId.length > 0) {
            $.ajax({
              type: 'GET',
              url: '/crm/users/group_tree_for_roles_and_permissions',
              data: { id: selectedSectionId },
            }).done(function (response) {
              // manually trigger the `select2:select` event
              var newOption1 = new Option('Select', '', false, false)
              groupSelect.empty().append(newOption1)
              groups = response.data
              if (groups.length > 0) {
                $.each(groups, function (key, value) {
                  groupSelect.append(
                    '<option value=' + value.id + '>' + value.text + '</option>',
                  )
                })
                if (selectedGroupId.length > 0) {
                  groupSelect
                    .find('option[value=' + selectedGroupId + ']')
                    .attr('selected', 'selected')
                }
              }
            })
          }
        }
        if (selectedGroupId != null){
          if (selectedGroupId.length > 0) {
            $.ajax({
              type: 'GET',
              url: '/crm/users/team_tree_for_roles_and_permissions',
              data: { id: selectedGroupId },
            }).done(function (response) {
              // manually trigger the `select2:select` event
              var newOption1 = new Option('Select', '', false, false)
              teamSelect.empty().append(newOption1)
              teams = response.data
              if (teams.length > 0) {
                $.each(teams, function (key, value) {
                  teamSelect.append(
                    '<option value=' + value.id + '>' + value.text + '</option>',
                  )
                })
                if (selectedTeamId.length > 0) {
                  teamSelect
                    .find('option[value=' + selectedTeamId + ']')
                    .attr('selected', 'selected')
                }
              }
            })
          }
        }
      })
    })
    function groups_by_section(obj) {
      var section_id = obj.value
      var sectionSelect = $(document.getElementById(obj.id))
      var groupSelect = $(
        document.getElementById(
          obj.id.split('_at_section')[0].concat('_at_group'),
        ),
      )
      $.ajax({
        method: 'get',
        data: { id: section_id },
        url: '/crm/users/group_tree_for_roles_and_permissions',
        dataType: 'json',
      })
        .done(function (response) {
          groups = response.data
          console.log('Reutnered GTS IN USER ROLES ARE :', groups)
          groupSelect.empty()
          var option = new Option(optionTranslation, '', false, false)
          groupSelect.append(option)
          groupSelect
            .select2({
              data: groups,
            })
            .trigger('change.select2')
        })
        .fail(function (response) {
          console.log('Failed')
        })
    }

    function teams_by_group(obj) {
      var group_id = obj.value
      var groupSelect = $(document.getElementById(obj.id))
      var teamSelect = $(
        document.getElementById(
          obj.id.split('_at_group')[0].concat('_at_team'),
        ),
      )
      $.ajax({
        method: 'get',
        data: { id: group_id },
        url: '/crm/users/team_tree_for_roles_and_permissions',
        dataType: 'json',
      })
        .done(function (response) {
          teams = response.data
          console.log('Reutnered TEAMS ARE :', teams)
          teamSelect.empty()
          var option = new Option(optionTranslation, '', false, false)
          teamSelect.append(option)
          teamSelect
            .select2({
              data: teams,
            })
            .trigger('change.select2')
        })
        .fail(function (response) {
          console.log('Failed')
        })
    }

    function clear_team_group_fields(obj) {
      //On change section, clear Team & Team leaders's Name.
      var sectionSelect = $(document.getElementById(obj.id))
      var groupSelect = $(
        document.getElementById(
          obj.id.split('_at_section')[0].concat('_at_group'),
        ),
      )
      var teamSelect = $(
        document.getElementById(
          obj.id.split('_at_section')[0].concat('_at_team'),
        ),
      )
      groupSelect.empty().trigger('change.select2')
      teamSelect.empty().trigger('change.select2')
      // On change section, clear Group & Group leader's name .
      var newOption1 = new Option(optionTranslation, '', false, false)
      var newOption2 = new Option(optionTranslation, '', false, false)
      groupSelect.append(newOption1).trigger('change.select2')
      teamSelect.append(newOption2).trigger('change.select2')
    }
  })
})(jQuery)
