// core version + navigation, pagination modules:
import Swiper from 'swiper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
// import Swiper and modules styles

$(document).ready(function () {
  const swiper = new Swiper('#resource-page-carousel .swiper', {
    // Optional parameters
    modules: [Navigation, Pagination, Autoplay],
    loop: true,
    autoplay: {
      delay: 2000,
    },

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })
})
