$(document).ready(function () {
  $('.registration_form').hide();
  $('.feedback_form').hide();
  $('.email_notification').hide();
  $('.form_action_type').hide();
  $('.validation_action_fields').hide();
  $('.assignment_action_fields').hide();
  $('.approve_action_fields').hide();
  $('.review_action_fields').hide();
  $('.document_action_fields').hide();

  $('#step_send_email_notification').on('change', function () {
    if ($(this).is(":checked")) {
      $('.email_notification').show();
    } else {
      $('.email_notification').hide();
    }
  })
  if ($('#step_send_email_notification').is(":checked")) {
    $('.email_notification').show();
  } else {
    $('.email_notification').hide();
  }

  if ($('#step_action_name').val() == "Form Submission") {
    $('.form_action_type').show();
  } else {
    $('.form_action_type').hide();
  }

  // if ($('#step_action_name').val() == "Send Email Notification") {
  //   $('.email_notification').show();
  // } else {
  //   $('.email_notification').hide();
  // }

  if ($('#step_action_name').val() == "Upload File") {
    $('.document_action_fields').show();
  } else {
    $('.document_action_fields').hide();
  }

  if ($('#step_action_name').val() == "Validate(Button Click)") {
    $('.validation_action_fields').show();
  } else {
    $('.validation_action_fields').hide();
  }
  if ($('#step_action_name').val() == "Approve(Button Click)") {
    $('.approve_action_fields').show();
  } else {
    $('.approve_action_fields').hide();
  }
  if ($('#step_action_name').val() == "Assign") {
    $('.assignment_action_fields').show();
  } else {
    $('.assignment_action_fields').hide();
  }
  if ($('#step_action_name').val() == "Review(Button Click)") {
    $('.review_action_fields').show();
    $('.feedback_form').show();
  } else {
    $('.review_action_fields').hide();
    $('.feedback_form').hide();
  }

  if ($('#step_form_action_type').val() == "Select Form") {
    $('.registration_form').show();
  } else {
    $('.registration_form').hide();
  }


  $('#step_action_name').on('change', function () {
    var action_type = $(this).val();
    if (action_type == "Form Submission") {
      $('.form_action_type').show();

      $('.email_notification').hide();
      $('.validation_action_fields').hide();
      $('.assignment_action_fields').hide();
      $('.approve_action_fields').hide();
      $('.review_action_fields').hide();
      $('.feedback_form').hide();
      $('.document_action_fields').hide();
    }
    // else if (action_type == "Send Email Notification") {
    //   $('.email_notification').show();
    //   $('.form_action_type').hide();
    //   $('.registration_form').hide();
    //   $('.validation_action_fields').hide();
    //   $('.assignment_action_fields').hide();
    //   $('.approve_action_fields').hide();
    //   $('.review_action_fields').hide();
    //   $('.feedback_form').hide();
    //   $('.document_action_fields').hide();
    // } 
    else if (action_type == "Validate(Button Click)") {
      $('.validation_action_fields').show();
      $('.assignment_action_fields').hide();
      $('.approve_action_fields').hide();
      $('.review_action_fields').hide();

      $('.email_notification').hide();
      $('.form_action_type').hide();
      $('.registration_form').hide();
      $('.feedback_form').hide();
      $('.document_action_fields').hide();
    } else if (action_type == "Assign") {
      $('.assignment_action_fields').show();
      $('.approve_action_fields').hide();
      $('.validation_action_fields').hide();
      $('.review_action_fields').hide();

      $('.email_notification').hide();
      $('.form_action_type').hide();
      $('.registration_form').hide();
      $('.feedback_form').hide();
      $('.document_action_fields').hide();
    } else if (action_type == "Approve(Button Click)") {
      $('.approve_action_fields').show();
      $('.validation_action_fields').hide();
      $('.assignment_action_fields').hide();
      $('.review_action_fields').hide();

      $('.email_notification').hide();
      $('.form_action_type').hide();
      $('.registration_form').hide();
      $('.feedback_form').hide();
      $('.document_action_fields').hide();
    } else if (action_type == "Review(Button Click)") {
      $('.review_action_fields').show();
      $('.feedback_form').show();
      $('.approve_action_fields').hide();
      $('.validation_action_fields').hide();
      $('.assignment_action_fields').hide();

      $('.email_notification').hide();
      $('.form_action_type').hide();
      $('.registration_form').hide();
      $('.document_action_fields').hide();
    } else if (action_type == "Upload File") {
      $('.document_action_fields').show();
      $('.review_action_fields').hide();
      $('.feedback_form').hide();
      $('.approve_action_fields').hide();
      $('.validation_action_fields').hide();
      $('.assignment_action_fields').hide();

      $('.email_notification').hide();
      $('.form_action_type').hide();
      $('.registration_form').hide();
    } else {

      $('.form_action_type').hide();
      $('.email_notification').hide();
      $('.registration_form').hide();
      $('.validation_action_fields').hide();
      $('.assignment_action_fields').hide();
      $('.approve_action_fields').hide();
      $('.review_action_fields').hide();
      $('.feedback_form').hide();
    }
  });

  $('#step_form_action_type').on('change', function () {
    var action_type = $(this).val();
    if (action_type == "Select Form") {
      $('.registration_form').show();
    } else {
      $('.registration_form').hide();
    }
  });

  // function get_territory_countries(territory_value) {
  //   $.ajax({
  //     method: "get",
  //     data: {
  //       id: territory_value
  //     },
  //     url: "/crm/territories/get_countries",
  //     dataType: "json"
  //   }).done(function(response) {
  //     console.log(response);
  //     countries = response.countries
  //     $('.site-country').empty();
  //     // $('.site-country').append('<option value="">Select..</option>')
  //     $.each(countries, function(key, value) {
  //       $('.site-country').append('<option value=' + value.id + '>' + value.name + '</option>')
  //     });
  //   }).fail(function(response) {
  //     console.log("Fail")
  //   })
  // }
})