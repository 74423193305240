require("backend/es-locale")
require("backend/dashboard")
require("backend/registrations")
require("backend/hierarchy-handler")
require("backend/users")
require("backend/initiatives")
require("backend/events")
require("backend/localities")
require("backend/sites")
require("backend/steps")
require("backend/sections")
require("backend/subscriptions")
require("backend/purchases")
require("backend/roles-tab")
require("backend/payment_modals")
require("backend/error_modals")
require("backend/resources")
require("backend/curriculums")
require("backend/pages")