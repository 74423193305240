$(document).ready(function () {
    $('.purchase_ticket_btn').on('click', function () {
        var ticket_id = $(this).data('ticketId')
        $.ajax({
            method: 'get',
            data: { ticket_id: ticket_id },
            url: '/crm/ticket_info',
            dataType: 'json'
        }).done(function (response) {
            $('#base_price').val(response.base_price);
            $('#purchase_total_price').val(response.base_price);
        }).fail(function (response) {

        })
        $('#purchase_ticket_id').val(ticket_id);
    })
    $('.ticket_qty').on('change', function () {
        var qty = $(this).val();
        var base_price = $('#base_price').val()
        $.ajax({
            method: 'get',
            data: { quantity: qty, base_price: base_price },
            url: '/crm/update_price',
            dataType: 'json'
        }).done(function (response) {
            console.log(response)
            $('#purchase_total_price').val(response.total_price)
        }).fail(function (response) {
            console.log("Fail")
        })
    })

})