$(document).ready(function () {
  named_period_is_checked();
  $('#locality_named_director').on('change', function () {
    named_period_is_checked();
  });

  function named_period_is_checked() {
    if ($('#locality_named_director').is(":checked")) {
      $('.named_director_work_period').show();
    } else {
      $('.named_director_work_period').hide();
    }
  }
})