$(document).ready(function () {
  var isGeneralSubscription = document.getElementById('subscription_general')
  var stripeConnecteAccountWrapper = document.getElementsByClassName(
    'stripe_connected_account_id_wrapper',
  )[0]
  
  if (isGeneralSubscription !== null) {
    toggleStripeConnectedAccountField()
  }
  
  if (isGeneralSubscription !== null) {
    isGeneralSubscription.addEventListener('change', function () {
      toggleStripeConnectedAccountField()
    })
  }
  function toggleStripeConnectedAccountField() {
    if (isGeneralSubscription.checked) {
      stripeConnecteAccountWrapper.setAttribute('hidden', true)
    } else {
      stripeConnecteAccountWrapper.removeAttribute('hidden')
    }
  }
})
