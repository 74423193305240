$(document).ready(function () {
  function dynamicColors() {
    var color = Math.floor(Math.random() * 16777215).toString(16)
    return '#'.concat(color)
  }

  function AllMembersDoughnutChart(data) {
    var $chart = $('#chart-doughnut-members')
    var data2 = {
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [
        'Miembros activos asociados a RC',
        'Miembros activos no asociados a RC',
        'Miembros no activos',
      ],
      datasets: [
        {
          data: data,
          borderWidth: 3,
          backgroundColor: ['#51B3C8', '#0A64ED', '#D1D1D1'],
        },
      ],
    }

    function init($this) {
      var membersChart = new Chart($this, {
        type: 'doughnut',
        data: data2,
        options: {
          rotation: -1 * Math.PI,
          circumference: 1 * Math.PI,
          cutoutPercentage: 70,
          legend: {
            display: false,
            position: 'bottom',
            align: 'right',
          },
          legendCallback: function (chart) {
            var text = []
            text.push('<ul class="0-legend">')
            var ds = chart.data.datasets[0]
            var sum = ds.data.reduce(function add(a, b) {
              return a + b
            }, 0)
            for (var i = 0; i < ds.data.length; i++) {
              text.push('<li class="my-2">')
              var perc = Math.round((100 * ds.data[i]) / sum, 0)
              var elem = `<span style="background-color: ${ds.backgroundColor[i]}"></span>`
              var dataLabels = `<div>${chart.data.labels[i]}</div>`
              var dataPoints = `<div class="ml-auto">${ds.data[i]}</div>`
              text.push(elem)
              text.push(dataLabels)
              text.push(dataPoints)
              text.push('</li>')
            }
            text.push('</ul>')
            return text.join('')
          },
        },
      })

      // Save to jQuery object

      $this.data('chart', membersChart)
      var myLegendContainer = document.getElementById('legend-members')
      // generate HTML legend
      myLegendContainer.innerHTML = membersChart.generateLegend()
    }
    if ($chart.length) {
      init($chart)
    }
  }
  function ApostolatesDoughnutChart(data) {
    var $chart = $('#chart-doughnut-apostolates')
    var backgroundColors = ['#D35CC1']
    var elemCount = data['apostolates_labels'].length - 1
    var i = 0
    while (i < elemCount) {
      backgroundColors.push(dynamicColors())
      i++
    }
    console.log('BG COLORS', backgroundColors)
    var data2 = {
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: data['apostolates_labels'],
      datasets: [
        {
          data: data['apostolates_members'],
          borderWidth: 3,
          backgroundColor: backgroundColors,
        },
      ],
    }

    function init($this) {
      var apostolatesChart = new Chart($this, {
        type: 'doughnut',
        data: data2,
        options: {
          cutoutPercentage: 50,
          legend: {
            display: false,
            position: 'bottom',
            align: 'right',
          },
          legendCallback: function (chart) {
            var text = []
            text.push('<ul class="0-legend">')
            var ds = chart.data.datasets[0]
            var sum = ds.data.reduce(function add(a, b) {
              return a + b
            }, 0)
            for (var i = 0; i < ds.data.length; i++) {
              text.push('<li class="my-2">')
              var elem = `<span style="background-color: ${ds.backgroundColor[i]}"></span>`
              var dataLabels = `<div>${chart.data.labels[i]}</div>`
              var dataPoints = `<div class="ml-auto">${ds.data[i]}</div>`
              text.push(elem)
              text.push(dataLabels)
              text.push(dataPoints)
              text.push('</li>')
            }
            text.push('</ul>')
            return text.join('')
          },
        },
      })

      // Save to jQuery object

      $this.data('chart', apostolatesChart)
      var myLegendContainer = document.getElementById('legend-apostolates')
      // generate HTML legend
      myLegendContainer.innerHTML = apostolatesChart.generateLegend()
    }
    if ($chart.length) {
      init($chart)
    }
  }
  function SpiritualDirectorsDoughnutChart(data) {
    var $chart = $('#chart-doughnut-spiritual-directors')
    var backgroundColors = ['#25D366', '#BB0000']
    console.log('SP DATA', data)
    var data2 = {
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [
        'Miembros con Director Espiritual',
        'Miembros sin Director Espiritual',
      ],
      datasets: [
        {
          data: data['spiritual_directors'],
          borderWidth: 3,
          backgroundColor: backgroundColors,
        },
      ],
    }

    function init($this) {
      var spiritualDirectorChart = new Chart($this, {
        type: 'doughnut',
        data: data2,
        options: {
          cutoutPercentage: 50,
          legend: {
            display: false,
            position: 'bottom',
            align: 'right',
          },
          legendCallback: function (chart) {
            var text = []
            text.push('<ul class="0-legend">')
            var ds = chart.data.datasets[0]
            for (var i = 0; i < ds.data.length; i++) {
              text.push('<li class="my-2">')
              var elem = `<span style="background-color: ${ds.backgroundColor[i]}"></span>`
              var dataLabels = `<div>${chart.data.labels[i]}</div>`
              var dataPoints = `<div class="ml-auto">${ds.data[i]}</div>`
              text.push(elem)
              text.push(dataLabels)
              text.push(dataPoints)
              text.push('</li>')
            }
            text.push('</ul>')
            return text.join('')
          },
        },
      })

      // Save to jQuery object

      $this.data('chart', spiritualDirectorChart)
      var myLegendContainer = document.getElementById(
        'legend-spiritual-directors'
      )
      // generate HTML legend
      myLegendContainer.innerHTML = spiritualDirectorChart.generateLegend()
    }
    if ($chart.length) {
      init($chart)
    }
  }
  function MembersAgeBarChart(data) {
    var $chart = $('#chart-bar-members-age')
    console.log('SP DATA', data)
    var labels = [
      '10-15',
      '16-20',
      '21-25',
      '26-30',
      '31-35',
      '36-40',
      '41-45',
      '46-50',
      '51-55',
      '56-60',
      '61-65',
      '66-70',
      '71-75',
      '76-80',
    ]
    var start = end = 0;
    for (let i = 0; i < labels.length; i++) {
      if (data.members_age[i] > 0 && i > 0) {
        start = i-1;
        break;
      }
    }
    for (let i = labels.length; i > 0; i--) {
      if (data.members_age[i] > 0) {
        end = i+2;
        break;
      }
    }
    var data2 = {
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: labels.slice(start, end),
      datasets: [
        {
          data: data.members_age.slice(start, end),
          borderWidth: 3,
          backgroundColor: '#0062FF',
        },
      ],
    }

    function init($this) {
      var membersAgeChart = new Chart($this, {
        type: 'bar',
        data: data2,
        options: {
          legend: {
            display: false,
            position: 'bottom',
            align: 'right',
          },
        },
      })
      // Save to jQuery object
      $this.data('chart', membersAgeChart)
    }
    if ($chart.length) {
      init($chart)
    }
  }
  function SubscriptionsBarChart(data) {
    var $chart = $('#chart-bar-subscriptions')
    var subscriptions = data['subscriptions_stats']
    var subscriptionsData = []
    subscriptions.forEach(function (elem) {
      // Object.entries returns an array whose elements are array of corresponding [keys, values] inside an object.
      subscriptionObject = Object.entries(elem)
      label = subscriptionObject[0][0]
      values = subscriptionObject[0][1]
      subscriptionsData.push({
        label: label,
        borderWidth: 3,
        data: values,
        backgroundColor: dynamicColors(),
      })
    })
    var data2 = {
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Ott',
        'Nov',
        'Dic',
      ],
      datasets: subscriptionsData,
    }

    function init($this) {
      var subscriptionsChart = new Chart($this, {
        type: 'bar',
        data: data2,
        options: {
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
          legend: {
            display: false,
            position: 'bottom',
            align: 'right',
          },
        },
      })
      // Save to jQuery object
      $this.data('chart', subscriptionsChart)
    }
    if ($chart.length) {
      init($chart)
    }
  }
  function TeamsDoughnutChart(args) {
    var elem = args['elem']
    var team_meeting_notes_stats = args['team_meeting_notes_stats']
    console.log('Team id', elem.dataset.teamId)
    var $chart = $('#chart-team-' + elem.dataset.teamId)
    var teamObj = team_meeting_notes_stats.filter(
      (f) => f['team'] == elem.dataset.teamId
    )
    if (teamObj.length > 0) {
      obj = teamObj[0]
      data = [
        obj.team_no_participation_percentage,
        obj.member_no_participation_relative_percentage,
        obj.member_participation_relative_percentage,
      ]
    } else {
      data = [0, 0, 0]
    }
    var data2 = {
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [
        '% no participation',
        '% of member no participation',
        '% of member participation',
      ],
      datasets: [
        {
          data: data,
          borderWidth: 3,
          backgroundColor: ['#D35CC1', '#3a7991', '#14aee4'],
        },
      ],
    }

    function init($this) {
      var teamChart = new Chart($this, {
        type: 'doughnut',
        data: data2,
        options: {
          title: {
            display: true,
            fontColor: '#000000',
            text: 'Gráfica di participación',
          },
          legend: {
            display: false,
            position: 'bottom',
            align: 'right',
          },
          legendCallback: function (chart) {
            var text = []
            text.push('<ul class="0-legend">')
            var ds = chart.data.datasets[0]
            for (var i = 0; i < ds.data.length; i++) {
              text.push('<li class="my-2">')
              var elem = `<span style="background-color: ${ds.backgroundColor[i]}"></span>`
              var dataLabels = `<div>${chart.data.labels[i]}</div>`
              var dataPoints = `<div class="ml-auto">${ds.data[i]}%</div>`
              text.push(elem)
              text.push(dataLabels)
              text.push(dataPoints)
              text.push('</li>')
            }
            text.push('</ul>')
            return text.join('')
          },
        },
      })

      // Save to jQuery object

      $this.data('chart', teamChart)
      var myLegendContainer = document.getElementById(
        `legend-team-${elem.dataset.teamId}`
      )
      // generate HTML legend
      myLegendContainer.innerHTML = teamChart.generateLegend()
    }
    if ($chart.length) {
      init($chart)
    }
  }
  var sectionId = document.getElementById('section_id')
  if (sectionId != null) {
    sectionId = sectionId.value
    $.ajax({
      method: 'get',
      url: '/crm/section_dashboard_charts_data',
      data: { section_id: sectionId },
      dataType: 'json',
    }).done(function (response) {
      members = Object.values(response['members'])
      apostolates = response['apostolates']
      spiritual_directors = response['spiritual_directors']
      members_age = response['members_age']
      subscriptions = response['subscriptions_stats']
      team_meeting_notes_stats = response['team_meeting_notes_stats']
      AllMembersDoughnutChart(members)
      ApostolatesDoughnutChart(apostolates)
      SpiritualDirectorsDoughnutChart(spiritual_directors)
      MembersAgeBarChart(members_age)
      SubscriptionsBarChart(subscriptions)
      teamChartElements = document.querySelectorAll(
        '[class="team-chart-canvas"]'
      )
      teamChartElements.forEach(function (elem) {
        TeamsDoughnutChart({
          elem: elem,
          team_meeting_notes_stats: team_meeting_notes_stats,
        })
      })
    })
  }
})
