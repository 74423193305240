$(document).ready(function () {
  /* Hierarchy Code below needs to be refactored in the next commit. */
  var url = window.location.href;
  var host = window.location.host;
  // var currentLang = window.location.href.split('locale=')[1];
  // heirarchy_type = "Territory"
  // $(".level-name").text(translateHierarchyNames(currentLang, heirarchy_type));

  // function translateHierarchyNames(currentLang, hierarchy_type) {
  //   currentLang = typeof currentLang === "undefined" ? "es" : currentLang
  //   var name = {
  //     en: {
  //       territory: "Territories",
  //       locality: "Localities",
  //       section: "Sections",
  //     },
  //     es: {
  //       territory: "Territorios",
  //       locality: "Localidades",
  //       section: "Secciones"
  //     }
  //   }
  //   if (hierarchy_type) {
  //     return name[currentLang][hierarchy_type.toLowerCase()];
  //   }

  // }

  // Updated Code For Hierarchy DDL on Sign-up Page.

  if (url.indexOf('http://' + host + '/users/signup') == -1) {
    var territory_id = $('#reg_territory option:selected').val();
    $('#reg_territory').on('change', function () {
      var territory_id = $('#reg_territory option:selected').val();
      territory_tree(territory_id)
    });
    $('#reg_locality').on('change', function () {
      var locality_id = $('#reg_locality option:selected').val();
      locality_tree(locality_id)
    });

    function territory_tree(territory_id) {
      var territorySelect = $('#reg_territory');
      var localitySelect = $('#reg_locality');
      $.ajax({
        type: 'GET',
        url: '/users/territory_tree',
        data: { id: territory_id }
      }).then(function (data) {
        console.log("Territories are:", data.territories)
        territorySelect.empty();
        var newOption1 = new Option("Select", "", false, false);
        territorySelect.append(newOption1).trigger('change.select2');
        // territorySelect.select2({data: data.territories}).trigger('change.select2')
        $.each(data.territories, function (key, value) {
          territorySelect.append('<option value=' + value.id + '>' + value.text + '</option>')
        })
        territorySelect.find('option[value=' + territory_id + ']').attr("selected", "selected")
        if (data.localities.length > 0) {
          localitySelect.empty();
          var newOption2 = new Option("Select", "", false, false);
          localitySelect.append(newOption2).trigger('change.select2');
          //localitySelect.select2({data: data.localities}).trigger('change.select2')
          $.each(data.localities, function (key, value) {
            localitySelect.append('<option value=' + value.id + '>' + value.text + '</option>')
          })
        }
      });
    }
    function locality_tree(locality_id) {
      var localitySelect = $('#reg_locality');
      var sectionSelect = $('#reg_section');
      $.ajax({
        type: 'GET',
        url: '/users/locality_tree',
        data: { id: locality_id }
      }).done(function (data) {
        // manually trigger the `select2:select` event
        var newOption1 = new Option("Select", "", false, false);
        var newOption2 = new Option("Select", "", false, false);
        localitySelect.empty().append(newOption1)
        sectionSelect.empty().append(newOption2)
        $.each(data.localities, function (key, value) {
          localitySelect.append('<option value=' + value.id + '>' + value.text + '</option>')
        })
        localitySelect.val(locality_id).attr('selected', 'selected')
        if (data.sections.length > 0) {
          $.each(data.sections, function (key, value) {
            sectionSelect.append('<option value=' + value.id + '>' + value.text + '</option>')
          })
        }
      });
    }
  }
  $('#territory_member_cb').on('change', function () {
    if ($(this).is(':checked')) {
      $('.locality_tree_control').hide();
      $('.section_tree_control').hide();
    } else {
      $('.locality_tree_control').show();
      $('.section_tree_control').show();
    }

  })
  $('#locality_member_cb').on('change', function () {
    if ($(this).is(':checked')) {
      $('.section_tree_control').hide();
    } else {
      $('.section_tree_control').show();
    }

  })
});