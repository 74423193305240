// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
// import Swiper and modules styles

$(document).ready(function () {
  const swiper = new Swiper('.dashboard-carousel .swiper', {
    // Optional parameters
    modules: [Navigation, Pagination, Autoplay],
    breakpoints: {
      320: {
        direction: 'horizontal'
      },
      768: {
        direction: 'vertical'
      }
    },
    loop: true,
    autoplay: {
      delay: 2000,
    },
  
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  
  });
  
  function LineChartMembers(data) {
    var $chart = $('#chart-for-members');
    // Methods

    function init($this) {
      var salesChart = new Chart($this, {
        type: 'line',
        options: {
          scales: {
            yAxes: [{
              gridLines: {
                color: Charts.colors.gray[901],
                zeroLineColor: Charts.colors.gray[901]
              },
              ticks: {

              }
            }]
          }
        },
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: [{
            borderColor: [Charts.colors.gray[902]],
            label: 'No. of Localities Registered',
            data: data
          }]
        }
      });

      // Save to jQuery object

      $this.data('chart', salesChart);

    };


    // Events

    if ($chart.length) {
      init($chart);
    }
  }

  function LineChartEvents(data) {
    var $chart = $('#chart-for-events');
    // Methods

    function init($this) {
      var salesChart = new Chart($this, {
        type: 'line',
        options: {
          scales: {
            yAxes: [{
              gridLines: {
                color: Charts.colors.gray[901],
                zeroLineColor: Charts.colors.gray[901]
              },
              ticks: {

              }
            }]
          }
        },
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: [{
            borderColor: [Charts.colors.gray[902]],
            label: 'No. of Localities Registered',
            data: data
          }]
        }
      });

      // Save to jQuery object

      $this.data('chart', salesChart);

    };


    // Events

    if ($chart.length) {
      init($chart);
    }
  }

  function RadarChart(data) {
    var $chart = $('#chart-radar');
    //
    // Methods
    //
    // Init chart
    function initChart($chart) {

      // Create chart
      var myRadarChart = new Chart($chart, {
        type: 'radar',
        data: {
          //data[0] contains lables
          labels: data[0],
          datasets: [{
            label: "Sections",
            data: data[1],
            borderWidth: 1,
            borderColor: 'rgba(193,66,66,1)',
            backgroundColor: 'rgba(248,164,174,1)',
            pointBackgroundColor: 'rgba(193,66,66,1)'
          }]
        },
      });

      // Save to jQuery object
      // $chart.data('chart', ordersChart);
      $chart.data('chart', myRadarChart);
    }


    // Init chart
    if ($chart.length) {
      initChart($chart);
    }
  }
  if (location.href.includes('crm')) {
    $.ajax({
      url: '/crm/get_data_for_radar_chart',
      method: 'get',
      dataType: 'json'
    }).done(function (response) {
      RadarChart(response);
      // console.log(JSON.stringify(response));
    }).fail(function (response) {
      console.log("Requet Fail");
    });

    $.ajax({
      url: '/crm/get_line_chart_for_events',
      method: 'get',
      dataType: 'json'
    }).done(function (response) {
      // console.log("Line Chart response");
      // console.log(JSON.stringify(response));
      LineChartEvents(response);
    }).fail(function (response) {
      console.log("Request Failed!");
    });

    $.ajax({
      url: '/crm/get_line_chart_for_members',
      method: 'get',
      dataType: 'json'
    }).done(function (response) {
      // console.log("Line Chart response");
      // console.log(JSON.stringify(response));
      LineChartMembers(response);
    }).fail(function (response) {
      console.log("Request Failed!");
    });
  }
});