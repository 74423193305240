// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
// import Swiper and modules styles

$(document).ready(function () {
  const swiper = new Swiper('.slider-landing .swiper', {
    // Optional parameters
    modules: [Navigation, Pagination, Autoplay],
    loop: true,
    autoplay: {
      delay: 2000,
    },
  
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
   
  });
  
  $('#dont_belong_to_section_cb, #dont_belong_to_locality_cb').on('click', function () {
    $('.user-team-group-info').hide();
  })

  var dob = '';
  $('.user-firstname-error').hide();
  $('.user-lastname-error').hide();
  $('.user-paternalname-error').hide();
  $('.user-city-error').hide();
  $('.user-state-error').hide();
  $('.user-country-error').hide();
  $('.user-sex-error').hide();

  $('.parent-firstname-error').hide();
  $('.parent-lastname-error').hide();
  $('.parent-email-error').hide();
  $('.accept-policy-error').hide();

  rc_coworker();
  has_children();
  has_associated();
  has_medication();
  has_allergies();
  $('.youth-territory-on-registration').hide();
  $('.youth-locality-on-registration').hide();
  $('.youth-section-on-registration').hide();
  // $('.parent-email').hide();
  $('.parent-registration-fields').hide();
  $('#user_is_rc_coworker').on('change', function () {
    rc_coworker();
  });
  $('#user_is_children').on('change', function () {
    has_children();
  });
  $('#user_is_associated').on('change', function () {
    has_associated();
  });
  $('#user_is_medication').on('change', function () {
    has_medication();
  });
  $('#user_is_allergies').on('change', function () {
    has_allergies();
  });

  function disable_signup_attributes() {
    $('#user_first_name').attr('disabled', 'disabled');
    $('#user_last_name').attr('disabled', 'disabled');
    $('#user_sex').attr('disabled', 'disabled');
    $('#user_city').attr('disabled', 'disabled');
    $('#user_country_id').attr('disabled', 'disabled');
    $('#user_email').attr('disabled', 'disabled');
    $('#user_password').attr('disabled', 'disabled');
  }

  function enable_signup_attributes() {
    $('#user_first_name').removeAttr('disabled');
    $('#user_last_name').removeAttr('disabled');
    $('#user_sex').removeAttr('disabled');
    $('#user_city').removeAttr('disabled');
    $('#user_country_id').removeAttr('disabled');
    $('#user_email').removeAttr('disabled');
    $('#user_password').removeAttr('disabled');
  }

  function validateCheckbox() {
    if ($('#user_accept_policy').is(':checked')) {
      return true;
    } else {
      return false;
    }
  }

  $('#reg_territory').on('change', function () {
    if ($('#reg_territory').val() == "") {
      $('.youth-territory-on-registration').show();
    } else {
      $('.youth-territory-on-registration').hide();
    }
  })

  $('#user_dob_1i').on('change', function () {
    var year = $('#user_dob_1i').val();
    var month = $('#user_dob_2i').val();
    var day = $('#user_dob_3i').val();
    var age = 18;
    var mydate = new Date();
    mydate.setFullYear(year, month - 1, day);

    var currdate = new Date();
    currdate.setFullYear(currdate.getFullYear() - age);
    dob = currdate - mydate
    if (dob < 0) {
      // $('.parent-email').show();
      $('.parent-registration-fields').show();
      return false;
    } else {
      // $('.parent-email').hide();
      $('.parent-registration-fields').hide();
      return false;
    }
  });
  $('#user_first_name').on('change keyup', function () {
    if ($('#user_first_name').val() == "") {
      $('.user-firstname-error').show();
    } else {
      $('.user-firstname-error').hide();
    }
  });
  $('#user_paternal_last_name').on('change keyup', function () {
    if ($('#user_paternal_last_name').val() == "") {
      $('.user-paternalname-error').show();
    } else {
      $('.user-paternalname-error').hide();
    }
  });
  // $('#user_last_name').on('change keyup', function () {
  //   if ($('#user_last_name').val() == "") {
  //     $('.user-lastname-error').show();
  //   } else {
  //     $('.user-lastname-error').hide();
  //   }
  // });
  $('#user_sex').on('change keyup', function () {
    if ($('#user_sex').val() == "") {
      $('.user-sex-error').show();
    } else {
      $('.user-sex-error').hide();
    }
  });
  $('#user_parent_first_name').on('change keyup', function () {
    if ($('#user_parent_first_name').val() == "") {
      $('.parent-firstname-error').show();
    } else {
      $('.parent-firstname-error').hide();
    }
  });
  $('#user_parent_last_name').on('change keyup', function () {
    if ($('#user_parent_last_name').val() == "") {
      $('.parent-lastname-error').show();
    } else {
      $('.parent-lastname-error').hide();
    }
  });
  $('#user_parent_email').on('change keyup', function () {
    if ($('#user_parent_email').val() == "") {
      $('.parent-email-error').show();
    } else {
      $('.parent-email-error').hide();
    }
  });
  $(".signup-form").submit(function (event) {
    if ($('#user_first_name').val() == "") {
      $('.user-firstname-error').show();
    } else {
      $('.user-firstname-error').hide();
    }
    if ($('#user_paternal_last_name').val() == "") {
      $('.user-paternalname-error').show();
    } else {
      $('.user-paternalname-error').hide();
    }
    
    if ($('#user_sex').val() == "") {
      $('.user-sex-error').show();
    } else {
      $('.user-sex-error').hide();
    }
    if (($('#user_first_name').val() != "") && ($('#user_paternal_last_name').val() != "") && ($('#user_sex').val() != "")) { } else {
      event.preventDefault();
    }
    if (dob < 0) {
      if ($('#user_parent_first_name').val() == "") {
        $('.parent-firstname-error').show();
      } else {
        $('.parent-firstname-error').hide();
      }
      if ($('#user_parent_last_name').val() == "") {
        $('.parent-lastname-error').show();
      } else {
        $('.parent-lastname-error').hide();
      }
      if ($('#user_parent_email').val() == "") {
        $('.parent-email-error').show();
      } else {
        $('.parent-email-error').hide();
      }
      if (($('#user_parent_first_name').val() != "") && ($('#user_parent_last_name').val() != "") && ($('#user_parent_email').val() != "")) { } else {
        event.preventDefault();
      }
    }

    if (!($('#territory_member_cb').is(":checked")) && !($('#locality_member_cb').is(":checked"))) {
      if (!validate_youth_territory_on_registration()) {
        event.preventDefault();
      } else {
        if (!validate_youth_locality_on_registration()) {
          event.preventDefault();
        } else {
          if (!validate_youth_section_on_registration()) {
            event.preventDefault();
          }
        }
      }

    }
    if ($('#territory_member_cb').is(":checked")) {
      if (!validate_youth_territory_on_registration()) {
        event.preventDefault();
      }
    }
    if ($('#locality_member_cb').is(":checked")) {
      if (!validate_youth_territory_on_registration()) {
        event.preventDefault();
      }
      if (!validate_youth_locality_on_registration()) {
        event.preventDefault();
      }
    }
    if (validateCheckbox()) {
      $('.accept-policy-error').hide();
    } else {
      event.preventDefault();
      $('.accept-policy-error').show();
    }

  });

  $('#reg_territory').on('change', function () {
    validate_youth_territory_on_registration();
  })
  $('#reg_locality').on('change', function () {
    validate_youth_territory_on_registration()
    validate_youth_locality_on_registration()
  })
  $('#reg_section').on('change', function () {
    validate_youth_section_on_registration()
  })

  function validate_youth_territory_on_registration() {
    var is_validated;
    if ($('#reg_territory').val() == "") {
      $('.youth-territory-on-registration').show();
      is_validated = false;
    } else {
      $('.youth-territory-on-registration').hide();
      is_validated = true;
    }
    return is_validated
  }

  function validate_youth_locality_on_registration() {
    var is_validated;
    if ($('#reg_locality').val() == "") {
      $('.youth-locality-on-registration').show();
      is_validated = false;
    } else {
      $('.youth-locality-on-registration').hide();
      is_validated = true;
    }
    return is_validated
  }

  function validate_youth_section_on_registration() {
    var is_validated;
    if ($('#reg_section').val() == "") {
      $('.youth-section-on-registration').show();
      is_validated = false;
    } else {
      $('.youth-section-on-registration').hide();
      is_validated = true;
    }
    return is_validated
  }
  $('#user_accept_policy').on('change', function () {
    if (validateCheckbox()) {
      $('.accept-policy-error').hide();
    } else {
      $('.accept-policy-error').show();
    }
  })
  $('#user_first_name').on('change', function () {
    $('#user_additional_data_first_name_teen').val($(this).val());
  });
  $('#user_last_name').on('change', function () {
    $('#user_additional_data_last_name_teen').val($(this).val());
  });

  $('#user_city').on('change keyup', function () {
    $('#user_additional_data_city_of_residence').val($(this).val());
  });

  $('#user_additional_data_city_of_residence').on('change keyup', function () {
    $('#user_city').val($(this).val());
  });

  // $('#user_team_director_id').on('change', function () {
  //   var director_team_id = $(this).val();
  //   if (director_team_id != "") {
  //     get_director_team_name(director_team_id)
  //   }
  // });

  function rc_coworker() {
    if ($('#user_is_rc_coworker').is(":checked"))
      $(".collaboration_date").show();
    else
      $(".collaboration_date").hide();

  }
  function has_children() {
    if ($('#user_is_children').is(":checked"))
      $(".has_children").show();
    else
      $(".has_children").hide();
  }

  function has_associated() {
    if ($('#user_is_associated').val() === "true"){
      $(".has_associated").show();
      document.querySelectorAll('.incorporation_date').forEach(function(element){       
        element.setAttribute('required', true)
      })

    }else {
      $(".has_associated").hide();
      document.querySelectorAll('.incorporation_date').forEach(function(element){       
        element.removeAttribute('required')
      })
    }
  }

  function has_medication() {
    if ($('#user_is_medication').is(":checked"))
      $(".has_medication").show();
    else
      $(".has_medication").hide();
  }

  function has_allergies() {
    if ($('#user_is_allergies').is(":checked"))
      $(".has_allergies").show();
    else
      $(".has_allergies").hide();
  }
  $('#user_section').on('change', function () {
    $('.user-team-group-info').show();
    var section_id = $('#user_section option:selected').val();
    console.log("User secion changed")
    if (section_id) {
      if (section_id > 0) {
        teams_by_section(section_id);
        get_spiritual_directors_list(section_id);
      }
    }

  });
  function teams_by_section(section_id) {
    $.ajax({
      method: "get",
      data: { id: section_id },
      url: "/crm/users/teams_by_section",
      dataType: "json"
    }).done(function (response) {
      groups = response.data
      console.log("Reutnered GTS:", groups)
      //On change section, clear Team & Team leaders's Name, clear Group & Group leader's name .
      clear_team_group_fields()
      $.each(groups, function (key, value) {
        group_name = value.name
        teams = value.teams
        // debugger;
        $("#user_team_name").select2({
          data: teams
        }).trigger('change.select2')
      })
    }).fail(function (response) {
      console.log("Failed")
    })
  }
  $('#user_team_name').on('change', function () {
    var team_value = $(this).val();
    if (team_value) {
      console.log("TD");
      get_director_names(team_value)
    }
  });

  function get_director_names(team_id) {
    $.ajax({
      method: "get",
      data: {
        id: team_id
      },
      url: "/crm/users/team_group_info_dropdown",
      dataType: "json"
    }).done(function (response) {
      if (response != undefined) {
        team_leader = response.team.team_leader
        team_leader_id = response.team.team_leader_id
        team_id = response.team.team_id
        group_leader = response.group.group_leader
        group_name = response.group.group_name
        console.log("TD REsponse", response)
        $('#user_team_director_id').empty().trigger('change.select2');
        var newTeamDirectorOption = new Option(team_leader, team_leader_id, false, false);
        $('#user_team_director_id').append(newTeamDirectorOption).trigger('change.select2');
        $('#user_current_group').val(group_name);
        $('#user_current_group_leader').val(group_leader);
      }
    }).fail(function (response) {
      console.log("Fail")
    })
  }

  function get_spiritual_directors_list(section_id) {
    $.ajax({
      data: { id: section_id },
      url: '/crm/users/get_spiritual_directors_list',
      method: 'get',
      dataType: "json"
    }).done(function (response) {
      // var director_id = $('#user_spiritual_director_id option:selected').val();
      $('#user_spiritual_director_id').empty();
      var newOption = new Option("Select.", "", false, false);
      $('#user_spiritual_director_id').append(newOption);
      $("#user_spiritual_director_id").select2({
        data: response.data
      })

    }).fail(function (response) {

    })
  }
  function clear_team_group_fields() {
    //On change section, clear Team & Team leaders's Name.
    $('#user_team_name').empty().trigger('change.select2');
    $('#user_team_director_id').empty().trigger('change.select2');
    // On change section, clear Group & Group leader's name .
    $('#user_current_group').val('');
    $('#user_current_group_leader').val('');
    var newOption1 = new Option("Select", "", false, false);
    var newOption2 = new Option("Select", "", false, false);
    $('#user_team_name').append(newOption1).trigger('change.select2');
    $('#user_team_director_id').append(newOption2).trigger('change.select2');
  }

  $('.search_territory').on('select2:select', function (e) {
    console.log('Selected Terr:', e.params.data)
    var territory_id = $('.search_territory option:selected').val()
    territory_tree(e.params.data.id)
  })
  $('.search_territory').on('select2:unselect', function (e) {
    // do something
    update_territory_tree(e.params.data.id)
  })

  $('.search_locality').on('select2:select', function (e) {
    console.log('Selected Loc:', e.params.data)
    locality_tree(e.params.data.id)
  })

  $('.search_locality').on('select2:unselect', function (e) {
    // do something
    update_locality_tree(e.params.data.id)
  })
  $('.search_section').on('select2:select', function (e) {
    console.log('Selected Section:', e.params.data)
    section_tree(e.params.data.id)
  })

  $('.search_section').on('select2:unselect', function (e) {
    // do something
    update_section_tree(e.params.data.id)
  })

  $('.search_group').on('select2:select', function (e) {
    console.log('Selected Group:', e.params.data)
    group_tree(e.params.data.id)
  })

  $('.search_group').on('select2:unselect', function (e) {
    // do something
    update_group_tree(e.params.data.id)
  })

  function update_territory_tree(territory_id) {
    var territorySelect = $('.search_territory')
    var localitySelect = $('.search_locality')
    $.ajax({
      type: 'GET',
      url: '/users/territory_tree_search_filter',
      data: { id: territory_id },
    }).then(function (data) {
      if (data.localities.length > 0) {
        $.each(data.localities, function (key, value) {
          // debugger;
          console.log('Value is', value)
          // localitySelect.find("option[value='"+ value +"']").remove(key);
          console.log(
            'Before: Option is ',
            $(".search_locality option[value='" + value.id + "']").text(),
          )
          $(".search_locality option[value='" + value.id + "']").remove()
        })
        localitySelect.trigger('change.select2')
      }
    })
  }
  
  function territory_tree(territory_id) {
    var territorySelect = $('.search_territory')
    var localitySelect = $('.search_locality')
    $.ajax({
      type: 'GET',
      url: '/users/territory_tree_search_filter',
      data: { id: territory_id },
    }).then(function (data) {
      console.log('Territories are:', data.territories)
      if (data.localities.length > 0) {
        console.log('Localities are', data.localities)
        $.each(data.localities, function (key, value) {
          localitySelect.append(
            '<option value=' + value.id + '>' + value.text + '</option>',
          )
        })
      }
    })
  }

  function locality_tree(locality_id) {
    var localitySelect = $('.search_locality')
    var sectionSelect = $('.search_section')
    var groupSelect = $('.search_group')
    $.ajax({
      type: 'GET',
      url: '/users/locality_tree_search_filter',
      data: { id: locality_id },
    }).done(function (data) {
      if (data.sections.length > 0) {
        sectionSelect.empty();
        groupSelect.empty();
        $.each(data.sections, function (key, value) {
          sectionSelect.append(
            '<option value=' + value.id + '>' + value.text + '</option>',
          )
        })
      }
    })
  }

  function update_locality_tree(locality_id) {
    var localitySelect = $('.search_locality')
    var sectionSelect = $('.search_section')
    var groupSelect = $('.search_group')
    $.ajax({
      type: 'GET',
      url: '/users/locality_tree_search_filter',
      data: { id: locality_id },
    }).done(function (data) {
      if (data.sections.length > 0) {
        sectionSelect.empty();
        groupSelect.empty();
        if (localitySelect.val() != ""){
          $.each(data.sections, function (key, value) {
            sectionSelect.append(
              '<option value=' + value.id + '>' + value.text + '</option>',
            )
          })
        }
      }
    })
  }

  function section_tree(section_id) {
    var sectionSelect = $('.search_section')
    var localitySelect = $('.search_locality')
    var groupSelect = $('.search_group')
    $.ajax({
      type: 'GET',
      url: '/users/section_tree_search_filter',
      data: { id: section_id, locality_id:  localitySelect.val()},
    }).done(function (data) {
      console.log('Groups are:', data.groups)
      if (data.groups.length > 0) {
        $.each(data.groups, function (key, value) {
          groupSelect.append(
            '<option value=' + value.id + '>' + value.text + '</option>',
          )
        })
      }
    })
  }

  function update_section_tree(section_id) {
    var groupSelect = $('.search_group')
    $.ajax({
      type: 'GET',
      url: '/users/section_tree_search_filter',
      data: { id: section_id },
    }).done(function (data) {
      $.each(data.groups, function (key, value) {
        // debugger;
        console.log('Value is', value)
        console.log(
          'Before: Option is ',
          $(".search_group option[value='" + value.id + "']").text(),
        )
        $(".search_group option[value='" + value.id + "']").remove()
      })
      groupSelect.trigger('change.select2')
    })
  }

  function group_tree(group_id) {
    var teamSelect = $('.search_team')
    $.ajax({
      type: 'GET',
      url: '/users/group_tree_search_filter',
      data: { id: group_id },
    }).done(function (data) {
      console.log('Teams are:', data.teams)
      if (data.teams.length > 0) {
        $.each(data.teams, function (key, value) {
          teamSelect.append(
            '<option value=' + value.id + '>' + value.text + '</option>',
          )
        })
      }
    })
  }

  function update_group_tree(group_id) {
    var teamSelect = $('.search_team')
    $.ajax({
      type: 'GET',
      url: '/users/group_tree_search_filter',
      data: { id: group_id },
    }).done(function (data) {
      $.each(data.teams, function (key, value) {
        // debugger;
        console.log('Value is', value)
        console.log(
          'Before: Option is ',
          $(".search_team option[value='" + value.id + "']").text(),
        )
        $(".search_team option[value='" + value.id + "']").remove()
      })
      teamSelect.trigger('change.select2')
    })
  }
  
});