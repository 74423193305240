global.Cookies = require("plugins/js.cookie")
require("plugins/jquery.scrollbar.min")
require("plugins/jquery-scrollLock.min")
require("plugins/select2.min")
require("plugins/jquery.validate.min")
require("plugins/Chart.min")
require("plugins/argon")
require("clipboard")
require('blueimp-file-upload')
global.Calendar = require("@fullcalendar/core").Calendar;
global.rrulePlugin = require("@fullcalendar/rrule").default;
global.allLocales = require("@fullcalendar/core/locales-all");
global.dayGridPlugin = require("@fullcalendar/daygrid").default;
// require("@fullcalendar/core")
// require("@fullcalendar/core/locales-all");
// require("@fullcalendar/daygrid").default;

