$(document).ready(function () {
  $(".toggle-password").click(function () {
    $(this).toggleClass("fa-eye fa-eye-slash");
    var input = $('#user_password');
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });
  $(".toggle-confirm-password").click(function () {
    $(this).toggleClass("fa-eye fa-eye-slash");
    var input = $('#user_password_confirmation');
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }

  });
  
  $('#modal-notification').modal({
    backdrop: 'static',
    keyboard: true,
    show: true
  });
  $('#validate-dob-on-first-access').modal({
    backdrop: 'static',
    keyboard: true,
    show: true
  });
  function countWords() {
    if ($('#user_additional_data_reason_to_be_coworker').length != 0) {
      var wordCount = $('#user_additional_data_reason_to_be_coworker').val().length;
      return wordCount;
    }
  }
  // Array.from(form.elements, (elem) =>{ if(elem.disabled){elem.removeAttribute("disabled")}})
  $('.validate_profile_survey_form').on("submit",function(event){
    event.preventDefault();
    var form = document.getElementsByClassName("validate_profile_survey_form")[0]
    Array.from(form.elements, (elem) =>{ if(elem.disabled){elem.removeAttribute("disabled")}})
    form.submit()
  })
});
  // var calculate_dob_in_yrs = function () {
  //   var currentLang = window.location.href.split('locale=')[1].split('&')[0]
  //   var year = $('#user_additional_data_date_of_birth_1i').val();
  //   var month = $('#user_additional_data_date_of_birth_2i').val();
  //   var day = $('#user_additional_data_date_of_birth_3i').val();
  //   var mydate = new Date();
  //   mydate.setFullYear(year, month - 1, day);
  //   var currdate = new Date();
  //   dob = currdate.getFullYear() - mydate.getFullYear()
  //   currentLang = typeof currentLang === "undefined" ? "es" : currentLang;
  //   if (currentLang == "es") {
  //     years = "anni";
  //   } else {
  //     years = "years";
  //   }
  //   $('.dob-in-yrs').html(dob + " " + years);
  // }
  // calculate_dob_in_yrs();
  // $('#user_additional_data_date_of_birth_1i').change(calculate_dob_in_yrs);
  // $('.words-count-error').hide();
  // $('#user_additional_data_reason_to_be_coworker').characterCounter({
  //   minlength: 0,
  //   maxlength: 1500,
  //   blockextra: false,
  //   position: 'bottom',
  //   counterclass: 'character-counter-indicator',
  //   alertclass: 'out-of-range',
  //   textformat: '[used]/[max]',
  // });

  // $('.save_and_exit').on('click', function (event) {

  //   $('#user_additional_data_t_shirt_size').prop('required', false);

  //   $('#user_additional_data_country_of_residence').prop('required', false);

  //   $('#user_additional_data_city_of_residence').prop('required', false);

  //   $('#user_additional_data_school').prop('required', false);

  //   $('#user_additional_data_grade').prop('required', false);

  //   $('#user_additional_data_home_phone_teen').prop('required', false);

  //   $('#user_additional_data_cell_phone_teen').prop('required', false);

  //   $('#user_additional_data_club_name').prop('required', false);

  //   $('#user_additional_data_years_in_ecyd').prop('required', false);

  //   $('#user_additional_data_first_name_director').prop('required', false);

  //   $('#user_additional_data_last_name_director').prop('required', false);

  //   $('#user_additional_data_director_email').prop('required', false);

  //   $('#user_additional_data_season_of_coworking').prop('required', false);

  //   // if (field.name === "user[additional_data][year]") {
  //   //   $('#user_additional_data_t_shirt_size').prop('required', false);
  //   // }

  //   $('#user_additional_data_reason_to_be_coworker').prop('required', false);

  //   $('#user_additional_data_father_or_tutor_first_name').prop('required', false);


  //   $('#user_additional_data_father_or_tutor_last_name').prop('required', false);

  //   $('#user_additional_data_email_father').prop('required', false);

  //   $('#user_additional_data_home_phone_father').prop('required', false);

  //   $('#user_additional_data_mobile_phone_father').prop('required', false);

  //   $('#user_additional_data_mother_or_tutor_first_name').prop('required', false);


  //   $('#user_additional_data_mother_or_tutor_last_name').prop('required', false);

  //   $('#user_additional_data_email_mother').prop('required', false);

  //   $('#user_additional_data_home_phone_mother').prop('required', false);

  //   $('#user_additional_data_mobile_phone_mother').prop('required', false);
  //   $('#user_additional_data_name_language_1').prop('required', false);

  //   $('#user_additional_data_speaking_skill_language_1').prop('required', false);

  //   $('#user_additional_data_reading_skill_language_1').prop('required', false);

  //   $('#user_additional_data_writing_skill_language_1').prop('required', false);

  //   $('#user_additional_data_name_language_2').prop('required', false);

  //   $('#user_additional_data_speaking_skill_language_2').prop('required', false);


  //   $('#user_additional_data_reading_skill_language_2').prop('required', false);

  //   $('#user_additional_data_writing_skill_language_2').prop('required', false);

  //   $('#user_additional_data_name_language_3').prop('required', false);

  //   $('#user_additional_data_speaking_skill_language_3').prop('required', false);


  //   $('#user_additional_data_reading_skill_language_3').prop('required', false);


  //   $('#user_additional_data_writing_skill_language_3').prop('required', false);


  //   $('#user_additional_data_chronic_illness').prop('required', false);


  //   $('#user_additional_data_allergies').prop('required', false);


  //   $('#user_additional_data_needs_specific_treatment_summer').prop('required', false);


  //   $('#user_additional_data_economic_possibility').prop('required', false);

  //   $('#user_additional_data_means_of_travel_expenses').prop('required', false);


  // });
  // $('.save_with_validation').on('click', function (event) {
  //   event.preventDefault();
  //   //$('.user_additional_data_t_shirt_size').removeAttr("required");
  //   var fields = $('.initiative_register_form').serializeArray();
  //   console.log(fields);
  //   jQuery.each(fields, function (i, field) {
  //     if (field.name === "user[additional_data][t_shirt_size]") {
  //       $('#user_additional_data_t_shirt_size').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][country_of_residence]") {
  //       $('#user_additional_data_country_of_residence').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][city_of_residence]") {
  //       $('#user_additional_data_city_of_residence').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][school]") {
  //       $('#user_additional_data_school').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][grade]") {
  //       $('#user_additional_data_grade').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][home_phone_teen]") {
  //       $('#user_additional_data_home_phone_teen').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][cell_phone_teen]") {
  //       $('#user_additional_data_cell_phone_teen').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][club_name]") {
  //       $('#user_additional_data_club_name').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][years_in_ecyd]") {
  //       $('#user_additional_data_years_in_ecyd').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][first_name_director]") {
  //       $('#user_additional_data_first_name_director').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][last_name_director]") {
  //       $('#user_additional_data_last_name_director').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][director_email]") {
  //       $('#user_additional_data_director_email').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][season_of_coworking]") {
  //       $('#user_additional_data_season_of_coworking').prop('required', true);
  //     }
  //     // if (field.name === "user[additional_data][year]") {
  //     //   $('#user_additional_data_t_shirt_size').prop('required', true);
  //     // }
  //     if (field.name === "user[additional_data][reason_to_be_coworker]") {
  //       $('#user_additional_data_reason_to_be_coworker').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][father_or_tutor_first_name]") {
  //       $('#user_additional_data_father_or_tutor_first_name').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][father_or_tutor_last_name]") {
  //       $('#user_additional_data_father_or_tutor_last_name').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][email_father]") {
  //       $('#user_additional_data_email_father').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][home_phone_father]") {
  //       $('#user_additional_data_home_phone_father').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][mobile_phone_father]") {
  //       $('#user_additional_data_mobile_phone_father').prop('required', true);
  //     }

  //     if (field.name === "user[additional_data][mother_or_tutor_first_name]") {
  //       $('#user_additional_data_mother_or_tutor_first_name').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][mother_or_tutor_last_name]") {
  //       $('#user_additional_data_mother_or_tutor_last_name').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][email_mother]") {
  //       $('#user_additional_data_email_mother').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][home_phone_mother]") {
  //       $('#user_additional_data_home_phone_mother').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][mobile_phone_mother]") {
  //       $('#user_additional_data_mobile_phone_mother').prop('required', true);
  //     }

  //     if (field.name === "user[additional_data][name_language_1]") {
  //       $('#user_additional_data_name_language_1').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][speaking_skill_language_1]") {
  //       $('#user_additional_data_speaking_skill_language_1').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][reading_skill_language_1]") {
  //       $('#user_additional_data_reading_skill_language_1').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][writing_skill_language_1]") {
  //       $('#user_additional_data_writing_skill_language_1').prop('required', true);
  //     }

  //     if (field.name === "user[additional_data][name_language_2]") {
  //       $('#user_additional_data_name_language_2').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][speaking_skill_language_2]") {
  //       $('#user_additional_data_speaking_skill_language_2').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][reading_skill_language_2]") {
  //       $('#user_additional_data_reading_skill_language_2').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][writing_skill_language_2]") {
  //       $('#user_additional_data_writing_skill_language_2').prop('required', true);
  //     }

  //     if (field.name === "user[additional_data][name_language_3]") {
  //       $('#user_additional_data_name_language_3').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][speaking_skill_language_3]") {
  //       $('#user_additional_data_speaking_skill_language_3').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][reading_skill_language_3]") {
  //       $('#user_additional_data_reading_skill_language_3').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][writing_skill_language_3]") {
  //       $('#user_additional_data_writing_skill_language_3').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][chronic_illness]") {
  //       $('#user_additional_data_chronic_illness').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][allergies]") {
  //       $('#user_additional_data_allergies').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][needs_specific_treatment_summer]") {
  //       $('#user_additional_data_needs_specific_treatment_summer').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][economic_possibility]") {
  //       $('#user_additional_data_economic_possibility').prop('required', true);
  //     }
  //     if (field.name === "user[additional_data][means_of_travel_expenses]") {
  //       $('#user_additional_data_means_of_travel_expenses').prop('required', true);
  //     }
  //   });

  //   if (countWords() <= 1500) {
  //     event.preventDefault();
  //     $('.words-count-error').show();
  //   } else {
  //     $('.words-count-error').hide();
  //     $('.initiative_register_form').submit();
  //   }
  // })

  