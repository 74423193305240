$(document).ready(function () {
  var isRecurrent = document.getElementById('curriculum_is_recurrent')
  var recurrentType = document.getElementById('curriculum_recurrent_type')
  var isGeneralCurriculum = document.getElementById('curriculum_general')
  var isGeneralLevel = document.getElementById('general_level_curriculum')

  var stripeConnecteAccountWrapper = document.getElementsByClassName(
    'stripe_connected_account_id_wrapper',
  )[0]
  var recurrentTypeLabel = document.getElementsByClassName(
    'curriculum_recurrent_type_label',
  )[0]
  var recurrentTypeWrapper = document.getElementsByClassName(
    'curriculum_recurrent_type',
  )[0]

  if (recurrentTypeWrapper != null) {
    recurrentTypeWrapper.setAttribute('hidden', true)
  }

  if (isRecurrent !== null) {
    isRecurrent.addEventListener('change', function () {
      if (isRecurrent.checked) {
        recurrentTypeWrapper.removeAttribute('hidden')
        recurrentType.setAttribute('required', 'true')
        recurrentTypeLabel.classList.add('required')
        recurrentTypeLabel.insertAdjacentHTML(
          'beforeend',
          "<abbr class='required_icon' title='required'>*</abbr>",
        )
      } else {
        recurrentType.removeAttribute('required')
        recurrentTypeWrapper.setAttribute('hidden', true)
        recurrentTypeLabel.classList.remove('required')
        recurrentTypeLabel.querySelector('abbr').remove()
      }
    })

    if (isRecurrent.checked) {
      recurrentTypeWrapper.removeAttribute('hidden')
      recurrentType.setAttribute('required', 'true')
      recurrentTypeLabel.classList.add('required')
      recurrentTypeLabel.insertAdjacentHTML(
        'beforeend',
        "<abbr class='required_icon' title='required'>*</abbr>",
      )
    } else {
      recurrentTypeWrapper.setAttribute('hidden', true)
      recurrentType.removeAttribute('required')
      recurrentTypeLabel.classList.remove('required')
    }
  }

  if (isGeneralCurriculum !== null) {
    toggleStripeConnectedAccountField()
    isGeneralCurriculum.addEventListener('change', function () {
      toggleStripeConnectedAccountField()
    })
  }

  if (isGeneralLevel != null) {
    if (isGeneralLevel.value === 'true') {
      stripeConnecteAccountWrapper.setAttribute('hidden', true)
    } else {
      stripeConnecteAccountWrapper.removeAttribute('hidden')
    }
  }

  function toggleStripeConnectedAccountField() {
    if (isGeneralCurriculum.checked) {
      stripeConnecteAccountWrapper.setAttribute('hidden', true)
    } else {
      stripeConnecteAccountWrapper.removeAttribute('hidden')
    }
  }
})
