$(document).ready(function () {
  $('.site-territory').on('change', function () {
    var territory_value = $(this).val();
    if (territory_value != "") {
      get_territory_countries(territory_value)
    }
  });

  function get_territory_countries(territory_value) {
    $.ajax({
      method: "get",
      data: {
        id: territory_value
      },
      url: "/crm/territories/get_countries",
      dataType: "json"
    }).done(function (response) {
      console.log(response);
      $('.site-country').empty();
      $('.site-country').append('<option value="">Select..</option>').trigger("change.select2")
      $('.site-country').select2({
        placeholder: "Select ..",
        data: response
      }).trigger('change.select2')
    }).fail(function (response) {
      console.log("Fail")
    })
  }
})