$(document).ready(function () {
  var isRecurrent = document.getElementById('event_is_recurrent')
  var recurrentType = document.getElementById('event_recurrent_type')
  var isGeneralEvent = document.getElementById('event_general')
  var isGeneralLevel = document.getElementById('general_level_event')

  var stripeConnecteAccountWrapper = document.getElementsByClassName(
    'stripe_connected_account_id_wrapper',
  )[0]
  var recurrentTypeLabel = document.getElementsByClassName(
    'event_recurrent_type_label',
  )[0]
  var recurrentTypeWrapper = document.getElementsByClassName(
    'event_recurrent_type',
  )[0]
  
  if (recurrentTypeWrapper != null) {
    recurrentTypeWrapper.setAttribute('hidden', true)
  }

  if (isRecurrent !== null) {
    isRecurrent.addEventListener('change', function () {
      if (isRecurrent.checked) {
        recurrentTypeWrapper.removeAttribute('hidden')
        recurrentType.setAttribute('required', 'true')
        recurrentTypeLabel.classList.add('required')
        recurrentTypeLabel.insertAdjacentHTML(
          'beforeend',
          "<abbr class='required_icon' title='required'>*</abbr>",
        )
      } else {
        recurrentType.removeAttribute('required')
        recurrentTypeWrapper.setAttribute('hidden', true)
        recurrentTypeLabel.classList.remove('required')
        recurrentTypeLabel.querySelector('abbr').remove()
      }
    })

    if (isRecurrent.checked) {
      recurrentTypeWrapper.removeAttribute('hidden')
      recurrentType.setAttribute('required', 'true')
      recurrentTypeLabel.classList.add('required')
      recurrentTypeLabel.insertAdjacentHTML(
        'beforeend',
        "<abbr class='required_icon' title='required'>*</abbr>",
      )
    } else {
      recurrentTypeWrapper.setAttribute('hidden', true)
      recurrentType.removeAttribute('required')
      recurrentTypeLabel.classList.remove('required')
    }
  }

  if (isGeneralEvent !== null) {
    toggleStripeConnectedAccountField()
    isGeneralEvent.addEventListener('change', function () {
      toggleStripeConnectedAccountField()
    })
  }

  if (isGeneralLevel != null) {
    if (isGeneralLevel.value === 'true') {
      stripeConnecteAccountWrapper.setAttribute('hidden', true)
    } else {
      stripeConnecteAccountWrapper.removeAttribute('hidden')
    }
  }

  function toggleStripeConnectedAccountField() {
    if (isGeneralEvent.checked) {
      stripeConnecteAccountWrapper.setAttribute('hidden', true)
    } else {
      stripeConnecteAccountWrapper.removeAttribute('hidden')
    }
  }
  // const stripe = Stripe('pk_test_587TJuERTRScPXhOEcvCsOCZ')
  // let elements
  // initiatlize_stripe()
  // document
  //   .querySelector('#payment-form')
  //   .addEventListener('submit', handleSubmit)

  // function initiatlize_stripe() {
  //   // Fetches a payment intent and captures the client secret
  //   const response = fetch('/crm/checkouts/create_payment_intent', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ id: 1 }),
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json()
  //       }
  //     })
  //     .then((data) => {
  //       console.log('Return data is ', data.clientSecret)
  //       var clientSecret = data.clientSecret
  //       const appearance = {
  //         theme: 'stripe',
  //       }
  //       elements = stripe.elements({ appearance, clientSecret })

  //       const paymentElement = elements.create('payment')
  //       paymentElement.mount('#payment-element')
  //     })
  //   // const { clientSecret } = response.json()
  // }
  // function handleSubmit(e) {
  //   e.preventDefault()
  //   setLoading(true)

  //   const { error } = stripe.confirmPayment({
  //     elements,
  //     confirmParams: {
  //       // Make sure to change this to your payment completion page
  //       return_url: 'http://localhost:3000/crm/checkouts/payment_status',
  //     },
  //   })

  //   // This point will only be reached if there is an immediate error when
  //   // confirming the payment. Otherwise, your customer will be redirected to
  //   // your `return_url`. For some payment methods like iDEAL, your customer will
  //   // be redirected to an intermediate site first to authorize the payment, then
  //   // redirected to the `return_url`.
  //   if (error.type === 'card_error' || error.type === 'validation_error') {
  //     showMessage(error.message)
  //   } else {
  //     showMessage('An unexpected error occurred.')
  //   }

  //   setLoading(false)
  // }

  // function showMessage(messageText) {
  //   const messageContainer = document.querySelector('#payment-message')

  //   messageContainer.classList.remove('hidden')
  //   messageContainer.textContent = messageText

  //   setTimeout(function () {
  //     messageContainer.classList.add('hidden')
  //     messageText.textContent = ''
  //   }, 4000)
  // }

  // // Show a spinner on payment submission
  // function setLoading(isLoading) {
  //   if (isLoading) {
  //     // Disable the button and show a spinner
  //     document.querySelector('#submit').disabled = true
  //     document.querySelector('#spinner').classList.remove('hidden')
  //     document.querySelector('#button-text').classList.add('hidden')
  //   } else {
  //     document.querySelector('#submit').disabled = false
  //     document.querySelector('#spinner').classList.add('hidden')
  //     document.querySelector('#button-text').classList.remove('hidden')
  //   }
  // }
})
