$(document).ready(function () {
  $('#invitation_link_for').on('change', function () {
    //var [option] = $(this).selectedOptions
    console.log("Option", $(this).val())
    if($(this).val() == "Initiative"){
      $('.initiative_registration_link_field').show()
      $("#initiative_registration_link").attr("disabled", false);
      
      $('.section_registration_link_field').hide()
      $("#section_registration_link").attr("disabled", true);
      
      $('.team_registration_link_field').hide()
      $("#team_registration_link").attr("disabled", true);
    }
    else if ($(this).val() == "Team"){
      $('.team_registration_link_field').show()
      $("#team_registration_link").attr("disabled", false);
      
      $('.initiative_registration_link_field').hide()
      $("#initiative_registration_link").attr("disabled", true);
      
      $('.section_registration_link_field').hide()
      $("#section_registration_link").attr("disabled", true);
    }
    else if ($(this).val() == "Section"){
      $('.section_registration_link_field').show()
      $("#section_registration_link").attr("disabled", false);
      
      $('.team_registration_link_field').hide()
      $("#team_registration_link").attr("disabled", true);
      
      $('.initiative_registration_link_field').hide()
      $("#initiative_registration_link").attr("disabled", true);
    }
    else {
      $('.section_registration_link_field').hide()
      $("#section_registration_link").attr("disabled", true);
      
      $('.team_registration_link_field').hide()
      $("#team_registration_link").attr("disabled", true);
      
      $('.initiative_registration_link_field').hide()
      $("#initiative_registration_link").attr("disabled", true);
    }
    // console.log("Option", option.value)
  })
  $('.send-link').on('click', function () {
    var registration_link = $(this).data('registrationLink');
    var initiative_id = $(this).data('initiativeId');
    $('#initiative_registration_link').val(registration_link);
    populate_initiative_levels_dropdown(initiative_id)
  })
  $('.send-link-from-users').on('click', function () {
    var email_address = $(this).data('userEmail');
    $('#invitee_email').val(email_address);
  })

  $('.send-section-link').on('click', function () {
    var registration_link = $(this).data('registrationLink');
    $('#section_registration_link').val(registration_link);
  })

  $('.send-team-link').on('click', function () {
    var registration_link = $(this).data('registrationLink');
    $('#team_registration_link').val(registration_link);
  });

  $('.join-team-youth').on('click', function () {
    var team_id = $(this).data('teamId');
    var decoded_email = $(this).data('decodedEmail');
    $('#team_id').val(team_id);
    $('#uad').val(decoded_email);
  });

  $('.join-group-youth').on('click', function () {
    var group_id = $(this).data('groupId');
    var decoded_email = $(this).data('decodedEmail');
    $('#group_id').val(group_id);
    $('#uid').val(decoded_email);
  });

  function populate_initiative_levels_dropdown(initiative_id) {
    var initiative_id = initiative_id
    $.ajax({
      data: {
        initiative_id: initiative_id
      },
      url: '/crm/initiatives/initiative_levels_dropdown',
      method: 'get',
      dataType: 'json'
    }).done(function (response) {
      console.log(response);
      initiative_levels = response.initiative_levels
      $('.initiative-levels-dropdown').empty();
      $('.initiative-levels-dropdown').prepend('<option selected></option>').select2({
        placeholder: "Select...",
        allowClear: true,
        //The below option is added because the search bar f select 2 dont work if placed under modal box. 
        dropdownParent: $("#send-initiative-link")
      });
      $.each(initiative_levels, function (key, value) {
        $('.initiative-levels-dropdown').append('<option value=' + value.id + '>' + value.name + '</option>')
      })
    }).fail(function (response) {
      console.log(response);
    });
  }
});